@charset "UTF-8";

/*
#overview
textareaスタイル

*/

textarea {

  @include _box-sizing();
  border: none;
  background: #fff;
  vertical-align: top;
  overflow: auto;


  /*
  #styleguide
  textarea-size-l

  ```
  <textarea class="textarea-size-l"></textarea>
  ```
  */
  &.textarea-size-l {
    width: 100%;
    max-width: 100%;
    height: 120px;
  }

  /*
  #styleguide
  textarea-disabled

  ```
  <textarea class="textarea-size-l" disabled></textarea>
  ```
  */
  &:disabled {
    background: $background_color_dark;
  }


}
