@charset "UTF-8";

/*
#overview
selectスタイル

*/

/*
#styleguide
select

```
<select name="" id="" class="sel-size-l">
  <option value="">0</option>
  <option value="">1</option>
  <option value="">2</option>
  <option value="">3</option>
</select>
```
*/

//初期読み込み時に一瞬見えるselectを非表示に
.select-design select {
  opacity: 0;

  &:focus {
    outline-offset: -2px;
    outline: -webkit-focus-ring-color auto 5px;
  }

}

select.select-design {
  opacity: 0;
}

.sel {
  @include _box-sizing();
  text-align: left;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border: 2px solid $theme_color_default;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  select {
    @include _box-sizing();
    cursor: pointer;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)"; /* ie 8 */
    border: none;
  }

  &:hover {
    opacity: .7;
  }

  // サイズ設定
  &.sel-size-auto {
    height: 44px;
  }

  &.sel-size-l {
    width: 300px;
    height: 44px;
  }

  &.sel-size-day {
    width: 80px;
    height: 40px;
  }

  &.sel-size-m {
    width: 200px;
    height: 44px;
  }

  &.sel-size-s {
    width: 120px;
    height: 30px;
  }

  &.sel-size-number {
    width: 65px;
    height: 30px;
  }

  &.sel-height-s {
    height: 30px;
  }

  &.sel-header-search {
    width: 238px;
    height: 35px;
  }

  // テキスト表示域
  & .sel-txt {
    @include _box-sizing();
    background-image: url(#{$IMG_PATH}icon/icn_pulldown_k.png)!important;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 13px!important;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    border-right: 5px solid transparent;
  }

  &.sel-size-auto .sel-txt,
  &.sel-size-l .sel-txt,
  &.sel-size-ml .sel-txt,
  &.sel-size-m .sel-txt {
    line-height: 40px;
  }

  &.sel-size-day .sel-txt {
    line-height: 36px;
  }

  &.sel-height-s .sel-txt,
  &.sel-size-number .sel-txt {
    line-height: 30px;
  }

  &.sel-header-search .sel-txt {
    line-height: 35px;
  }

  &.sel-size-thin .sel-txt {
    line-height: 28px;
  }

  &.disabled {
    background: $background_color_dark;

    &:hover {
      opacity: 1;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    select {
      cursor: default;
    }

  }

  &.focus {
    //box-shadow: 0 0 5px 1px $theme_color_light;
  }

}
