@charset "UTF-8";

.global-header {

  .global-header-inner {
    position: relative;

    .global-header-logo {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
      text-align: center;
      padding: 15px 0;
      background: #fff;
      z-index: 100;
      -webkit-transform: translate3d(0,0,0);
      -moz-transform: translate3d(0,0,0);
      -ms-transform: translate3d(0,0,0);
      -o-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);

      .menu-humb {
        position: absolute;
        left: 15px;
        top: 50%;
        margin-top: -8px;
        width: 21px;
        height: 17px;

        .menu-humb-inner {
          position: relative;
          width: 100%;
          height: 100%;

          span {
            display: block;
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
            background: $theme_color_default;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            -webkit-transition: all .2s;
            -moz-transition: all .2s;
            -ms-transition: all .2s;
            -o-transition: all .2s;
            transition: all .2s;
            opacity: 1;

            &.top {
              top: 0;
            }

            &.mid01, &.mid02 {
              top: 7px;
            }

            &.btm {
              bottom: 0;
            }

          }

        } // .menu-humb-inner

        &.open {

          span {

            &.top {
              opacity: 0;
              -webkit-transform: translateX(10px);
              -moz-transform: translateX(10px);
              -ms-transform: translateX(10px);
              -o-transform: translateX(10px);
              transform: translateX(10px);
            }

            &.mid01 {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &.mid02 {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }

            &.btm {
              opacity: 0;
              -webkit-transform: translateX(-10px);
              -moz-transform: translateX(-10px);
              -ms-transform: translateX(-10px);
              -o-transform: translateX(-10px);
              transform: translateX(-10px);
            }

          }

        }

      } // .menu-humb

    } // .global-header-logo

    .global-header-ttl {
      padding: 5px 0 10px;
      text-align: center;
      font-size: 16px;
      border-bottom: 1px solid #cccccc;
      font-weight: normal;

      span {
        vertical-align: middle;
        img {
          vertical-align: middle;
          opacity: .6;
          width: 20px;
        }
      }

      .name {
        position: relative;
        top: 2px;
      }

    } // .global-header-ttl

    .global-header-user {
      display: none;
    } // .global-header-user

  } // .global-header-inner

} // .global-header