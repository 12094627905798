@charset "UTF-8";

.m-form {
  margin: auto;
  position: relative;

  @include design_default_set;

  .error-area {
    padding: 15px;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
    color: #b94a48;
  }

  .msg-area {
    padding: 15px;
    text-align: center;
    font-size: 15px;
  }

  .ph {
    border: 2px solid $theme_color_default;
    padding: 2px;
    display: inline-block;
  }

  .input-text {
    border: 1px solid $theme_color_default;
    padding: 7px 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  textarea {
    border: 1px solid $theme_color_default;
    padding: 7px 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .radio-group, .checkbox-group {
    vertical-align: middle;
    margin-right: 30px;
    .pseudo-checkbox, .pseudo-radio {
      margin-right: 10px;
    }
  }

  .select-group {
    vertical-align: middle;
    margin-right: 10px;
    .sel {
      margin-right: 5px;
    }
  }

  .form-group {
    margin: 10px 0;
  }

  .error {
    margin-top: 5px;
    font-size: 12px;
    color: red;
    font-weight: bold;
  }

  table {
    display: block;

    tbody, tr, th, td {
      display: block;
    }

    th {
      padding: 10px;
      background: $background_theme_color_light;
      color: $theme_color_default;
      font-weight: bold;
    }

    td {
      margin: 10px 0;
    }
    
    .public-setting {
      text-align: left;

      & > span {
        padding-right: 15px;
        vertical-align: middle;
      }
    }

    tr {
      &.error-row {
        td {
          padding-top: 0;

          .error {
            margin-top: 0;
          }
        }
      }
    }

  }

  .form-btns {
    text-align: center;
    margin: 20px 0 40px;

    .btn {
      margin: 0 7px;
    }

    & > div {
      margin: 15px 0;
    }

  }


  &.login-form {

    .input-text {
      border-color: #d1d1d1;
      text-align: left;

      &:-moz-placeholder {
      }
      &:-ms-input-placeholder {
      }
      &::-webkit-input-placeholder {
      }
    }

  }

  &.forgot-form {

    .input-text {
      border: 1px solid #d1d1d1;
      background: #f2f2f2;

      &:-moz-placeholder {
        font-size: 12px;
      }
      &:-ms-input-placeholder {
        font-size: 12px;
      }
      &::-webkit-input-placeholder {
        font-size: 12px;
      }

    }

    table {

      tr {

        th:first-child {
          color: $font_color_default;
        }
      }
    }

    .mini {
      font-size: 10px;
      padding-left: 5px;
    }

  }


}