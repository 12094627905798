@charset "UTF-8";

.global-menu {
  position: fixed;
  top: 0;
  width: 80%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling : touch;
  z-index: 102;
  background: #1f1f1f;
  left: -80%;
  transform: translate3d(0, 0, 0);

  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  -ms-transition: all .2s;
  -o-transition: all .2s;
  transition: all .2s;

  .global-menu-inner {
    width: 100%;

    .global-menu-box {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .pc-hidden {
        display: hidden;
      }

      .global-menu-list {
        width: 100%;

        li {
          width: 100%;
          padding-bottom: 17px;

          a {
            text-decoration: none;
            position: relative;
            height: 55px;
            display: table;
            table-layout: fixed;
            width: 100%;

            .home {
              background: url(#{$IMG_PATH}icon/gl_icn_home_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }

            .portal {
              background: url(#{$IMG_PATH}icon/icon_portal_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }

            .forum {
              background: url(#{$IMG_PATH}icon/gl_icn_forum_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }

            .catalogue {
              background: url(#{$IMG_PATH}icon/gl_icn_catalogue_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }

            .courseListRef {
              background: url(#{$IMG_PATH}icon/icn_courselist_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }
            
            .attend-progress{
              background: url(#{$IMG_PATH}icon/icn_attend_progress_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }

            .portfolio {
              background: url(#{$IMG_PATH}icon/gl_icn_portfolio_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }
            
            .education {
              background: url(#{$IMG_PATH}icon/icn_education_pack_w.png) center center no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }

            .icn, .name {
              display: table-cell;
              vertical-align: middle;
            }

            .icn, .name {
              opacity: .4;
            }

            &.current {
              background: #00405E;

              .home {
                background: url(#{$IMG_PATH}icon/gl_icn_home_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .portal {
                background: url(#{$IMG_PATH}icon/icon_portal_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .forum {
                background: url(#{$IMG_PATH}icon/gl_icn_forum_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .catalogue {
                background: url(#{$IMG_PATH}icon/gl_icn_catalogue_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .courseListRef {
                background: url(#{$IMG_PATH}icon/icn_courselist_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }
              
              .attend-progress {
                 background: url(#{$IMG_PATH}icon/icn_attend_progress_b.png) center center no-repeat;
                 -webkit-background-size: contain;
                 background-size: contain;
              }

              .portfolio {
                background: url(#{$IMG_PATH}icon/gl_icn_portfolio_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }
              
              .education {
                background: url(#{$IMG_PATH}icon/icn_education_pack_b.png) center center no-repeat;
                -webkit-background-size: contain;
                background-size: contain;
              }

              .icn, .name {
                opacity: 1;
                color: #3483AF;
              }
            }

            .name {
              text-align: left;
              text-decoration: none;
              color: #F1F1F1;
              font-size: 18px;
              opacity: 1;
              padding-right: 5px;
            }

            .icn {
              text-align: center;
              width: 60px;

              img {
                width: 20px;
              }
            }



          } // a

        } // li

        .admin-menu{
          padding-top: 47px;
        }
      } // .global-menu-list

    } // .global-menu-box

    .global-menu-user {
      padding: 30px 15px;
      color: #fff;

      .layout-table {
        @include LayoutTable();

        & > div {
          @include LayoutCell();
          vertical-align: middle;
        }

        .ph {
          width: 40px;
          vertical-align: middle;

          .inner {
            position: relative;

            @include sts_notice() {
              right: 1px;
              top: 2px;
            }
          }

          .circle {
            display: block;
            overflow: hidden;
            width: 40px;
            height: 40px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;

            .user-ph {
              display: block;
              width: 100%;
              height: 100%;
              background-position: center center;
              background-repeat: no-repeat;
              -webkit-background-size: cover;
              background-size: cover;
            }

          }

        }
        .text {
          padding-left: 20px;
          min-width: 100px;

          .btn-logout {
            display: block;
            width: 100px;
            height: 20px;
            margin-top: 5px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            color: #fff;
            border: 1px solid #fff;
            font-size: 12px;
            text-decoration: none;
            background: url(#{$IMG_PATH}icon/icn_logout_w.png) left center no-repeat;
            background-size: 13px;
            background-position: 12px 2px;
            opacity: 0.8;
          }
        }
      }
    }

    .global-menu-ctr {
      display: none;
    } // .global-menu-ctr

    .global-menu-toggle {
      //display: none;
      position: absolute;
      height: 100%;
      width: 5px;
      right: 0px;
      top: 0;
      background-color: #82BEF0;
    }
  }
} // .global-menu