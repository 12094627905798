@charset "UTF-8";

/*
#overview
色設定

サイトの色について変数を設定しています。
*/

/*
#colors
テーマ色

@$theme_color_default #164FB8
@$theme_color_dark #0E3377
@$accent_color_light #FFD24D
@$accent_color_dark #FF9326
@$notification_color #FF0000
*/
$theme_color_default: #0b3182;
$theme_color_light: #37bef0;
$accent_color_light: #FFD24D;
$accent_color_default: #ee7800;
$accent_color_dark: #ee7800;
$notification_color: #FF0000;

/*
#colors
フォントカラー

@$font_color_default #666
@$font_color_light #fff
@$font_color_strong #333
@$font_color_price #B20000
@$font_color_alert #f00
@$link_color_default: #54EB8
*/
$font_color_default: #333;
$font_color_light: #888;
$font_color_strong: #333;
$font_color_alert: #f00;

$font_color_theme: #143395;
$font_color_theme_light: #7ecef4;

$link_color_default: #154EB8;

/*
#colors
ボーダー色

@$background_color_default #fff
@$background_color_dark #eee
@$border_color_theme_light #5C84CD
@$border_color_theme_dark #123F93
*/
$border_color_default: #ccc;

/*
#colors
背景色

@$background_color_default #fff
@$background_color_dark #eee
*/
$background_color_default: #fff;
$background_theme_color_contents: #bae3f9;
$background_theme_color_light: #dff2fc;
$background_color_dark: #444444;
$background_progress_01: #009be4;
$background_progress_02: #38bfff;

/*
#colors
ボタン色

@$btn_border_color_default $theme_color_default
@$btn_font_color_default $theme_color_default
@$btn_bg_color_next $theme_color_default
@$btn_bg_color_back #666
*/
$btn_border_color_default: $theme_color_default;
$btn_font_color_default: $theme_color_default;
$btn_bg_color_next: $theme_color_default;
$btn_bg_color_back: #666;
$btn_color_cart: #C72226;
