@charset "UTF-8";

/* ---------------------------------------------------------------

  Base setting

--------------------------------------------------------------- */

/*  base style
------------------------------------------ */
html {
}

body {
  font-family: $font_family_default;
  font-size: $font_size_default;
  line-height: $line_height_default;
  background: $background_color_default;
  color: $font_color_default;
}

:root * {
  font-family: $font_family_default;
}

//@media all and (-ms-high-contrast:none){
//  :root * {
//    font-family: "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", Verdana, "ＭＳ Ｐゴシック", "MS P Gothic", Arial, Helvetica, sans-serif!important;
//  } /* IE10~ */
//}


/*  Anchor default
------------------------------------------ */

a {
  color: $link_color_default;
  -webkit-tap-highlight-color: rgba(0,0,0,.3);
}

/*  input , textarea Font
------------------------------------------ */
input,textarea {
  font-family: $font_family_default;
  font-family: "ＭＳ Ｐゴシック", "MS PGothic", sans-serif \9;
  -webkit-tap-highlight-color: rgba(0,0,0,.3);
}

label {
  -webkit-tap-highlight-color: rgba(0,0,0,.3);
}

.container {
  @include design_default_set;
}

.wrap {
  padding-top: 52px;

  &.menu-open {

    &:after {
      position: fixed;
      z-index: 101;
      @include PseudoElements();
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
      left: 0;
      top: 0;
    }
  }
}

.sp-hidden {
  display: none!important;
}