@charset "UTF-8";

/*
#overview
btnスタイル

*/

/*
#styleguide
btn

```
<a href="#" class="btn btn-size-m btn-red">更新</a>
<a href="#" class="btn btn-size-m btn-orange">進む</a>
<a href="#" class="btn btn-size-m btn-gray">戻る</a>
<a href="#" class="btn btn-size-m btn-gray">検索</a>
<a href="#" class="btn btn-size-m btn-g-border">詳細</a>
```
*/

.btn,
button.btn,
input.btn,
a.btn {
  border: none;
  display: inline-block;
  color: $theme_color_default;
  font-weight: bold;

  &.with-img {
    position: relative;

    img {
      vertical-align: middle;
      margin-right: 15px;
      margin-left: -35px;
      position: relative;
      top: -2px;
      width: 20px;

      &.left {
        margin-left: 0;
        margin-right: 0;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -10px;
      }
    }
  }


  &.btn-search {
    background: $accent_color_dark url(#{$IMG_PATH}icon/icn_search_w.png) 20px center no-repeat;
    -webkit-background-size: 20px;
    background-size: 20px;
    color: #fff;
    border: none;
    font-weight: normal;
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 16px;
    padding: 0;
  }

  &.btn-chapter-start {
    text-decoration: none;
    position: relative;
    background: $accent_color_dark;
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    &:after {
      @include PseudoElements();
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(#{$IMG_PATH}common/arrow_btn_chapter_start.png) left top no-repeat;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }

  &.btn-chapter-detail {
    text-decoration: none;
    background: $theme_color_light;
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }

  &.btn-start {
    @extend .btn-chapter-start;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    text-indent: -10px;
  }


  &.btn-upl {
    background: $accent_color_dark;
    position: relative;
    width: 100px;
    height: 30px;
    line-height: 30px;
    font-size: 10px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    text-indent: -20px;
    text-decoration: none;

    &:after {
      @include PseudoElements();
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(#{$IMG_PATH}icon/icn_upload_w.png) left top no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }

  &.btn-dll {
    background: $theme_color_light;
    position: relative;
    width: 100px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    text-indent: -10px;
    text-decoration: none;

    &:after {
      @include PseudoElements();
      position: absolute;
      width: 16px;
      height: 16px;
      background: url(#{$IMG_PATH}icon/icn_download_w.png) left top no-repeat;
      -webkit-background-size: contain;
      background-size: contain;
      right: 10px;
      top: 50%;
      margin-top: -8px;
    }
  }


  &.btn-gray {
    background: #999999;
    position: relative;
    width: 140px;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    color: $font_color_default;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    text-decoration: none;
    text-align: center;
  }

  &.btn-submit {
    position: relative;
    border: 1px solid $accent_color_dark;
    width: 240px;
    height: 34px;
    line-height: 32px;
    font-size: 16px;
    color: $accent_color_dark;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: transparent;
    padding: 0;

    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;

    &.btn-blue {
      border: 1px solid $theme_color_light;
      color: $theme_color_light;
    }
  }

  &.btn-back {
    position: relative;
    border: 1px solid #aaa;
    width: 240px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    color: #aaa;
    font-weight: normal;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    text-decoration: none;
  }

}

.ui-btns {
  margin: 20px 0;
  text-align: center;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }

  &.left {
    text-align: left;

    .btn {
      margin-left: 0;
    }

  }

  .btn {
    margin: 0 7px;
  }


  & > div {
    width: 48%;
    float: right;
    &:first-child {
      float: left;
    }

    .btn {
      margin: 0;
    }
  }

}