@charset "UTF-8";

.c-no-login-header {
  padding: 20px;

  .c-no-login-header-inner {

  }

  .logo {
    margin-bottom: 10px;
    text-align: center;
    img {
      width: 150px;
    }
  }

  .lang-nav {

    ul {
      text-align: center;
      li {
        margin: 0 10px;
        display: inline-block;

        a {
          font-size: 10px;
        }
      }
    }
  }

}