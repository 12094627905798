@charset "UTF-8";

.page-login {
  margin: 10px;

  .page-inner {
    padding-top: 20px;

    .page-contents {
      text-align: center;

      .logo {
        margin-bottom: 30px;

        img {
          width: 150px;
        }
      }

      .form-btns {
        margin-top: 30px;
        margin-bottom: 30px;
      }


    }
  }
}