@charset "UTF-8";

.m-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  z-index: 100;
  background: rgba(0, 0, 0, .5);

  .modal-inner {
    opacity: 0;
    position: relative;
    top: 50%;
    padding: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #fff;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
    -moz-box-shadow:  0 0 8px 1px rgba(0, 0, 0, 0.5);
    box-shadow:  0 0 8px 1px rgba(0, 0, 0, 0.5);
  }

  .msg-comp {
    padding: 10px 0;
    font-size: 16px;
  }

  .btn-modal-close {
    margin-top: 15px;
    width: 176px;
    height: 32px;
    line-height: 32px;
    background: #666;
    color: #fff;
    font-weight: normal;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 16px;
    text-decoration: none;
  }

}