@charset "UTF-8";

.page-forgot {
  margin: 10px;

  .page-inner {

    .page-contents {

      .heading-page-name {
        font-weight: bold;
        position: relative;
        font-size: 18px;
        padding-left: 15px;
        border-bottom: 2px solid $theme_color_default;
        padding-bottom: 2px;
        margin-bottom: 25px;

        &:before {
          @include PseudoElements();
          position: absolute;
          left: 0;
          top: 2px;
          width: 4px;
          height: 20px;
          background: $theme_color_default;
        }
      }

      .lead {
        margin-bottom: 30px;
      }

      .form-btns {
        margin-top: 40px;
      }

    }
  }
}