@charset "UTF-8";

/*
#overview
ベースレイアウト

*/

.wrap {
  position: relative;

  .global-header {

  } // .header

  .global-menu {
  } // .menu

  .container {
  } // .container

  .contents {
    .contents-inner {
    }
  }

  &.unnecessary_global {
    padding-top: 0;

  }

} // .wrap