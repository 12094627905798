@charset "UTF-8";

.m-lst-course {

  ul {
    padding-bottom: 10px;

    li {
      .inner {
        -webkit-tap-highlight-color: rgba(0,0,0,.3);
        @include sts_notice(){
          right: 3px;
          top: 3px;
        }
        position: relative;
        background: #fff;
        padding: 10px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        border: 1px solid #b2b2b2;
        cursor: pointer;
        margin-bottom: 10px;

        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -ms-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;

        .layout {
          overflow: hidden;
          & > div {
            float: left;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 50%;
          }
        }

        .ph {
          width: 40%;
          img {
            width: 100%;
          }
        }

        .text {
          padding-left: 10px;

          .course-name {
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          } // .course-name

          .mid {
            padding: 48px 0 3px 120px;
            position: relative;
            overflow: hidden;

            .days {
              margin-top: -44px;
              float: right;
              min-width: 55px;
              text-align: center;

              .days-number {
                font-size: 20px;
                padding-left: 20px;
                line-height: 1;
                color: $font_color_theme;
                background: url(#{$IMG_PATH}icon/icn_watch_c.png) left center no-repeat;
                -webkit-background-size: 15px;
                background-size: 15px;
                display: inline-block;
              }

              &.past {
                .days-number {
                  background: url(#{$IMG_PATH}icon/icn_watch_o.png) left center no-repeat;
                  -webkit-background-size: 15px;
                  background-size: 15px;
                  color: $accent_color_default;
                }
              }

              .days-text {
                color: $font_color_theme_light;
                text-align: center;
                font-size: 12px;
                display: block;
              }

            } // .days

            .next-chapter {
              position: absolute;
              left: 0;
              bottom: 3px;
              font-weight: bold;
              font-size: 12px;
              padding-left: 10px;
              background: url(#{$IMG_PATH}module/arrow_next_chapter.png) left center no-repeat;
            }
          } // .mid

          .chapter-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 30px;
            height: 30px;
            padding: 0 8px;
            color: #fff;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            background: $accent_color_dark;
            -webkit-tap-highlight-color: rgba(0,0,0,.3);

            &.finish {
              background: #b4b4b4;
              color: #fff;
              &:hover {
              }
            }



          } // .chapter-name
        } // .text

        .progress {

          & > div:first-child {
            padding-right: 8px;
          }

          & > div:last-child {
            padding-left: 8px;
          }

          .numbers {
            p {
              display: inline-block;
              padding-left: 10px;
              color: $font_color_theme;

              span {
                font-size: 24px;
                padding-right: 3px;
              }
            }
          }
        }

      } // .inner
    } // li
  } // ul

} // .m-lst-course