@charset "UTF-8";

/*
#overview
inputスタイル

*/
input[type="checkbox"].pseudo, input[type="radio"].pseudo {
  opacity: 0;
}

label {
  //@include HoverOpacity();
  cursor: pointer;
}

input:focus,
textarea:focus {
  outline-offset: 0;
  outline: none;
}


/*
#styleguide
input[type="checkbox"]

```
<input type="checkbox" id="foo01" class="pseudo">
```
*/

.pseudo-checkbox {
  @include _box-sizing();
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  border: 2px solid $theme_color_default;
  vertical-align: middle;
  cursor: pointer;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;

  &.on,
  &:hover {
  }

  & .checked {
    position: absolute;
    width: 20px;
    height: 20px;
    left: -2px;
    top: -2px;
    display: none;
    z-index: 1;
    background: url(#{$IMG_PATH}common/input_checked.png) center center no-repeat;
  }

  &.on .checked {
    display: block;
  }

  /*
  #styleguide
  input[type="checkbox"]

  ```
  <input type="checkbox" id="bar01" class="pseudo" disabled>
  ```
  */
  &.disabled {
    cursor: default;

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

  }

  &.focus {
    outline-offset: 0;
    outline: none;
    //box-shadow: 0 0 5px 1px $theme_color_light;
  }

  input {
    position: fixed;
    top: -26px;
    left: -26px;
    //display: none;
  }

  &.input-checkbox-switch {
    border: none;
    width: 56px;
    height: 24px;
    display: inline-block;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    background: #ccc;
    position: relative;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;

    .checked {
      display: block;
      width: 18px;
      height: 18px;
      background: #fff;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      border-radius: 9px;
      position: absolute;
      left: auto;
      right: 3px;
      top: 3px;
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -ms-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }

    &.on {
      background: $theme_color_default;
      .checked {
        -webkit-transform: translateX(-32px);
        -moz-transform: translateX(-32px);
        -ms-transform: translateX(-32px);
        -o-transform: translateX(-32px);
        transform: translateX(-32px);
      }
    }

  }

}

/*
#styleguide
input[type="radio"]

```
<input type="radio" id="foo03" name="foo" class="pseudo">
```
*/

.pseudo-radio {
  @include _box-sizing();
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid $theme_color_default;

  &.on,
  &:hover {
    -webkit-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .checked {
    position: absolute;
    width: 10px;
    height: 10px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    left: 3px;
    top: 3px;
    background: $theme_color_default;
    display: none;
    z-index: 1;
  }

  &.on .checked {
    display: block;
  }

  &.focus {
    outline-offset: 0;
    outline: none;
    //box-shadow: 0 0 5px 1px $theme_color_light;
  }

  /*
  #styleguide
  input[type="radio"]

  ```
  <input type="radio" id="foo04" name="foo" class="pseudo" disabled>
  ```
  */
  &.disabled {
    cursor: default;

    &:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

  }

  input {
    position: absolute;
    top: -20px;
    left: -20px;
  }

}


