@charset "UTF-8";

// ---------------------------------------------------------------
//
//  Import Settings
//
// ---------------------------------------------------------------

@import "settings/define";
@import "settings/view";
@import "settings/color";
@import "settings/font";

// ---------------------------------------------------------------
//
//  Import Core
//
// ---------------------------------------------------------------

@import "../core/class";
@import "../core/function";
@import "../core/snippet";

//  Base Style
// ---------------------------------------------------------------

@import "base/mixin";
@import "base/reset";
@import "base/base";
@import "base/layout";

//  Js libs
// ---------------------------------------------------------------

@import "js_libs/perfect-scrollbar";

//  Common
// ---------------------------------------------------------------

@import "common/sts_notice";


//  Globals
// ---------------------------------------------------------------

@import "globals/global_header";
@import "globals/global_menu";
//{globals}

//  Components
// ---------------------------------------------------------------

@import "components/no_login_header";
//{components}

//  Modules
// ---------------------------------------------------------------

@import "modules/page_heading_area";
@import "modules/lst_course";
@import "modules/form";
@import "modules/modal";
//{modules}

//  Parts
// ---------------------------------------------------------------

@import "parts/input";
@import "parts/select";
@import "parts/textarea";
@import "parts/btn";
//@import "parts/pager";
//@import "parts/icon";
//@import "parts/item_list";
@import "parts/heading";
//@import "parts/common_font";
@import "parts/progress_bar";
//{parts}

//  Pages
// ---------------------------------------------------------------

@import "pages/login";
@import "pages/forgot";
@import "pages/error";
//{pages}
